export const addolRouter = [
  // addol经营数据首页
  {
    path: "/addol/homeData",
    name: "homeData",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/homeData.vue"
      )
  },
  // 订单列表（tab）
  {
    path: "/addol/orderList",
    name: "orderList",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/order/orderList.vue"
      )
  },
  // 订单列表（订单状态）
  {
    path: "/addol/orderStatusList",
    name: "orderStatusList",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/order/orderStatusList.vue"
      )
  },
  // 订单详情
  {
    path: "/addol/orderDetail",
    name: "orderDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/order/orderDetail.vue"
      )
  },
  // 我的资金
  {
    path: "/addol/fundsList",
    name: "fundsList",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/accountFunds/fundsList.vue"
      )
  },
  // 资金详情页（我的余额，我的货款）
  {
    path: "/addol/fundsDeatil",
    name: "fundsDeatil",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/accountFunds/fundsDeatil.vue"
      )
  },
  // 资金详情列表页（我的余额，我的货款）
  {
    path: "/addol/fundsSpecificDetail",
    name: "fundsSpecificDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/accountFunds/fundsSpecificDetail.vue"
      )
  },
  // 尊享卡明细
  {
    path: "/addol/exclusiveCardDetail",
    name: "exclusiveCardDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/accountFunds/exclusiveCardDetail.vue"
      )
  },
  // 银行卡列表
  {
    path: "/addol/bankCardList",
    name: "bankCardList",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/bankCard/bankCardList.vue"
      )
  },
  // 添加银行卡
  {
    path: "/addol/addBankCard",
    name: "addBankCard",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/bankCard/addBankCard.vue"
      )
  },
  // 提现
  {
    path: "/addol/withdrawSuccess",
    name: "withdrawSuccess",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/withdraw/withdrawSuccess.vue"
      )
  },
  // 提现成功
  {
    path: "/addol/withdraw",
    name: "withdraw",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/withdraw/withdraw.vue"
      )
  },
  // 奖励绑定银行卡
  {
    path: "/addol/rewardBankCard",
    name: "rewardBankCard",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/bankCard/rewardBankCard.vue"
      )
  },
  //我的客户
  {
    path: "/addol/myclient",
    name: "myclient",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/myclient.vue"
      ),
    // meta: { keepAlive: true }
  },
  //客户搜素
  {
    path: "/addol/searchclient",
    name: "searchclient",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/searchclient.vue"
      )
    // meta: { keepAlive: true }
  },
  //客户详情
  {
    path: "/addol/clientdetail",
    name: "clientdetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/clientdetail.vue"
      ),
      meta: { keepAlive: true }
  },
  //我的意向
  {
    path: "/addol/myintention",
    name: "myintention",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/myintention.vue"
      )
  },
  //我的意向
  {
    path: "/addol/intentionsearch",
    name: "intentionsearch",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/addol/intentionsearch.vue"
      )
  }
];
