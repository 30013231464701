/**
 * 一、H5调用客户端原生可用方法如下:
 * 1.调用分享方法inviteUpgrade，id商品id
 *
 *  调用方式如下(如无参数，参数对象则不传):
 *  this.$bridge.call('inviteUpgrade', {
      params1: 'params1',
      params2: 'params2'
    }).then(res => {
      console.log('客户端返回: ', res)
    }).catch(err) {
      console.log('err: ', err)
    }
 *
 * 二、 H5跳转客户端页面（通用方法）: visitAppPage
 *  1. 跳转个人主页, 参数: pageName-personalHomepage, userId-用户id
 *  2. 跳转联系人列表去任职, 参数: pageName-contactsList, type[teamLeader-队长任职/groupLeader-组长任职], schoolId-学校id（type类型为teamLeader时传）, campusId-校区id（type类型为groupLeader时传）
 *  3. 跳转校区客服组团体页面, 参数: pageName-campusServiceTeam, orgId-团体id
 *  4. 跳转发瞬间页面, 参数: pageName-createMoment, orgId-团体id, type-类型[text-纯文/imgAndText-图文/video-视频]
 *  5. 跳转发文章页面, 参数: pageName-createArticle, orgId-团体id
 *  6. 跳转发活动页面, 参数: pageName-createActivity, orgId-团体id
 *  7. 跳转瞬间详情, 参数: pageName-momentDetail, id-瞬间详情id, statusType[published-已发布/approving-审批中/rejected-已拒绝], type-类型[text-纯文/imgAndText-图文/video-视频]
 *  8. 跳转话题详情, 参数: pageName-topicDetail, id-话题详情id, statusType[published-已发布/approving-审批中/rejected-已拒绝]
 *  9. 跳转文章详情, 参数: pageName-articleDetail, id-文章详情id, statusType[published-已发布/approving-审批中/rejected-已拒绝]
 *  10. 跳转活动详情, 参数: pageName-activityDetail, id-活动详情id, statusType[published-已发布/approving-审批中/rejected-已拒绝]
 *  11. 跳转申请学校队长审核页面, 参数: pageName-teamLeader, id-审核id
 *  12. 跳转申请校区组长审核页面, 参数: pageName-groupLeader, id-审核id
 *  13. 跳转至创建团队页面, 参数: pageName-createOrg
 *  14. 跳转至团队主页, 参数: pageName-orgHomepage, orgId-团队id
 *  15. 跳转至用户所在校区的团体列表, 参数: pageName-orgList, schoolId-学校id, campusId-校区id
 *  16. 跳转至客服组聊天页面, 参数: pageName-campusServiceGroupChat, chatId-聊天id
 *  17. 跳转至客户端订单列表, 参数: pageName-orderList
 *  18. 跳转客户端遇知己页面, 参数: pageName-confidantPage
 *  19. h5跳转客户端邀请新成员列表页面（推荐团队给好友）, 参数: pageName-inviteMembers, socName-社团名称, groupId-,社团id, shareImageUrl-社团logo地址
 *  20. h5跳转商品详情, 参数: pageName-goodsDetail, goodsId-商品id, campusId-校区id
 *  21. h5跳转至拨打电话, 参数: pageName-getRingup, mobile-要拨打的电话号码
 *
 *  调用方式如下(pageName必传，其它参数没有则不传):
 *  this.$bridge.call('visitAppPage', {
 *    pageName: 'personalHomepage',  // 页面名称
      params1: 'params1',  // 其它参数1
      params2: 'params2'  // 其它参数2
    }).then(res => {
      console.log('客户端返回: ', res)
    }).catch(err) {
      console.log('err: ', err)
    }
 *
 * 三、 退出webview并指定刷新某个客户端页面（通用方法）: appExitRefresh
 *  1. 社团主页, 参数: pageName-societyApplication
 *
 *  调用方式如下(pageName必传，其它参数没有则不传):
 *  this.$bridge.call('appExitRefresh', {
 *    pageName: 'societyApplication',  // 页面名称
      params1: 'params1',  // 其它参数1
      params2: 'params2'  // 其它参数2
    }).then(res => {
      console.log('客户端返回: ', res)
    }).catch(err) {
      console.log('err: ', err)
    }
 *
 **/

import emitter from './events'
import browser from '@/utils/browser'

class Bridge {
  constructor() {
    this.browser = {
      platform: '',
      isQQ: false,
      isWeiXin: false
    }
    this.getBrowserInfo()
  }

  getBrowserInfo() {
    const {
      versions: {
        ios,
        isQQ,
        isWeiXin
      }
    } = browser()
    const platform = ios ? 'ios' : 'android'
    this.browser = {
      platform,
      isQQ,
      isWeiXin
    }
  }

  registerEvent(eventName, params) {
    console.log('browser info: ', this.browser)
    console.log('事件名称: ', eventName)
    console.log('系统名称: ', this.browser.platform)
    console.log('call params: ', params)
    return new Promise((resolve, reject) => {
      try {
        if (this.browser.platform === 'ios') {
          window.webkit.messageHandlers.KDLSNative.postMessage({
            funcName: eventName,
            params
          })
        } else {
          // 此处必须直接调用，否则安卓会报错
          window.KDLSNative[eventName](JSON.stringify(params))
        }
        console.log('window.kiddolEmitter', window.kiddolEmitter)
        resolve()
      } catch (err) {
        console.log('window.kiddolEmitter', window.kiddolEmitter)
        kiddolEmit(eventName, err)
        console.log(`调用原生方法 ${eventName} 失败`, err)
        reject(err)
      }
    })
  }

  call(eventName, params = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        if (eventName === 'getEncryptionInfo') {
          const {
            emitEventName
          } = params
          emitter.on(emitEventName, function (res) {
            resolve(res)
          })
        } else {
          emitter.removeAllListeners(eventName)
          emitter.on(eventName, function (res) {
            resolve(res)
          })
        }

        await this.registerEvent(eventName, params)
      } catch (err) {
        reject(err)
      }
    })
  }
}

export default new Bridge()
