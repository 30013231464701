import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import baseUrl from "./utils/env";
import Bridge from "./utils/bridge/index";
import vConsole from "vconsole";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import browser from "@/utils/browser";
import Vant from "vant";
import VueClipboard from "vue-clipboard2";
import { Toast, Dialog } from "vant";
import "vant/lib/index.css";
import { NoticeBar } from "vant";
import md5 from "js-md5";

import { Swipe, SwipeItem, List, Tab, Tabs, CountDown, Stepper } from "vant";

Vue.use(VueClipboard);
Vue.use(CountDown);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Vant);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Stepper);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.config.ignoredElements = ["wx-open-subscribe"];

if (process.env.VUE_APP_ENV == "development" || process.env.VUE_APP_ENV == "test") {
  var vconsole = new vConsole();
}
console.log("VUE_APP_ENV", process.env.VUE_APP_ENV);
axios.defaults.baseURL = baseUrl;
Vue.prototype.$bridge = Bridge;
Vue.prototype.$axios = axios;
Vue.prototype.$httpUrl = baseUrl;
Vue.config.productionTip = false;
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$md5 = md5;

Vue.prototype.$clientSource = () => {
  console.log(browser().versions);
  var res = "1";
  if (browser().versions.isWeiXin == true) {
    res = "2";
  }
  // res = "2";
  return res;
};

Vue.prototype.$isWx = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};

window.assistGoldData = {};
Vue.prototype.$setAssistGoldData = data => {
  window.assistGoldData = data;
};

console.log(process.env.NODE_ENV);
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线，$bus就是当前应用的vm
  }
}).$mount("#app");
