export const activityRouter = [{
    path: '/checkIn/list',
    name: 'checkInList',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/checkIn/index.vue'),
  },
  {
    path: '/checkIn/detail',
    name: 'checkInDetail',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/checkIn/checkInDetail.vue'),
  },
  {
    path: '/checkIn/checkInRank',
    name: 'checkInRank',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/checkIn/checkInRank.vue'),
  },
  {
    path: '/checkIn/checkInRules',
    name: 'checkInRules',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/checkIn/checkInRules.vue'),
  },

  // 超值团
  {
    path: '/superGruopBuy/invitation',
    name: 'superGruopBuyInvitation',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/superGruopBuy/invitation.vue'),
  },
  {
    path: '/superGruopBuy/gruoperRule',
    name: 'gruoperRule',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/superGruopBuy/gruoperRule.vue'),
  },
  {
    path: '/superGruopBuy/gruopSelfRewards',
    name: 'gruopSelfRewards', //团队个人业绩
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/superGruopBuy/gruopSelfRewards.vue'),
  },
  {
    path: '/superGruopBuy/gruopSelfRewardsDetail',
    name: 'gruopSelfRewardsDetail', //团队个人业绩明细
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/superGruopBuy/gruopSelfRewardsDetail.vue'),
  },

  //新版会员中心3.0
  {
    path: '/memberCenter/index',
    name: 'memberCenterNew',
    // component: () => import( /* webpackChunkName: "about" */
    //   '../views/memberCenter/index.vue'),
    component: () => import( /* webpackChunkName: "about" */
      '../views/memberCenter/modeVer4.vue'),
  },
  {
    path: '/memberCenter/modeVer3',
    name: 'memberCenterNew',
    component: () => import( /* webpackChunkName: "about" */
      '../views/memberCenter/index.vue'),
  },
  //新版会员中心4.0
  {
    path: '/memberCenter/modeVer4',
    name: 'memberCenterModeVer4',
    component: () => import( /* webpackChunkName: "about" */
      '../views/memberCenter/modeVer4.vue'),
  },

  //周年庆 
  {
    path: '/anniversary',
    name: 'anniversary',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/anniversary/index.vue'),
  },

  //国庆招募活动
  // 推广
  {
    path: '/nationalDay/one',
    name: 'nationalDay-one',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/nationalDay/one.vue'),
  },
  // 推荐
  {
    path: '/nationalDay/two',
    name: 'nationalDay-two',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/nationalDay/two.vue'),
  },

  // 多买多赠
  {
    path: '/giftDescription',
    name: 'giftDescription',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/buyAndPresent/description.vue'),
  },
  {
    path: '/giftDetail',
    name: 'giftDetail',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/buyAndPresent/detail.vue'),
  },
  // 尊享卡推广
  { //长高高
    path: '/cycleCard/promotion',
    name: 'cycleCardPromotion',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/cycleCard/promotion.vue'),
  },
  { //艾比克
    path: '/cycleCard/promotion2',
    name: 'cycleCardPromotion2',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/cycleCard/promotion2.vue'),
  },

  // 尊享卡客户列表
  {
    path: '/cycleCard/customer',
    name: 'cycleCardCustomer',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/cycleCard/customer.vue'),
  },
  // 尊享卡佣金明细
  {
    path: '/cycleCard/statisticsList',
    name: 'cycleCardStatisticsList',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/cycleCard/statisticsList.vue'),
  },

  // 店主招募
  {
    path: '/practiceKb/teamPracticeKbStatistics',
    name: 'teamPracticeKbStatistics',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/practiceKb/teamPracticeKbStatistics.vue'),
  },

  // 店主招募进度
  {
    path: '/practiceKb/teamPracticeKbStatus',
    name: 'teamPracticeKbStatus',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/practiceKb/teamPracticeKbStatus.vue'),
  },

  //夺宝活动页面
  {
    path: '/treasure',
    name: 'treasure',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/treasure/index.vue'),
  },
  //夺宝活动详情
  {
    path: '/treasure/detail',
    name: 'treasureDetail',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/treasure/detail.vue'),
  },
  //夺宝活动往期详情
  {
    path: '/treasure/phaseList',
    name: 'phaseList',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/treasure/phaseList.vue'),
  },
  //夺宝活动往期详情
  {
    path: '/treasure/queryPhaseDetail',
    name: 'queryPhaseDetail',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/treasure/queryPhaseDetail.vue'),
  },
  //夺宝活动抽奖记录
  {
    path: '/treasure/drawRecord',
    name: 'drawRecord',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/treasure/drawRecord.vue'),
  },
  //夺宝活动规则
  {
    path: '/treasure/rule',
    name: 'treasureRule',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/treasure/rule.vue'),
  },
  //金豆列表
  {
    path: '/treasure/goldBean',
    name: 'goldBean',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/treasure/goldBean.vue'),
  },


  //VTN
  {
    path: '/VTN',
    name: 'VTN',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/VTN/index.vue'),
  },
  {
    path: '/VTN/pinkCard',
    name: 'VTN',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/VTN/card.vue'),
  },
  {
    path: '/VTN/blackCard',
    name: 'VTN',
    component: () => import( /* webpackChunkName: "about" */
      '../views/activity/VTN/card.vue'),
  },

  //test
  {
    path: '/test/weappTest',
    name: 'weappTest',
    component: () => import( /* webpackChunkName: "about" */
      '../views/test/weappTest.vue'),
  },
];
