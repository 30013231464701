import Vue from "vue";
import VueRouter from "vue-router";
import { activityRouter } from "./activityRouter";
import { addolRouter } from "./addolRouter";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "activityList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/activityList.vue")
  },
  {
    path: "/share",
    name: "Home",
    // component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
    component: () => import(/* webpackChunkName: "about" */ "../views/memberCenter.vue")
  },
  {
    path: "/memberCenter",
    name: "memberCenter",
    component: () => import(/* webpackChunkName: "about" */ "../views/memberCenter.vue")
  },
  {
    path: "/activityRule",
    name: "activityRule",
    component: () => import(/* webpackChunkName: "about" */ "../views/activityRule.vue")
  },
  {
    path: "/couponRule",
    name: "couponRule",
    component: () => import(/* webpackChunkName: "about" */ "../views/couponRule.vue")
  },
  {
    path: "/bannerToPkg",
    name: "bannerToPkg",
    component: () => import(/* webpackChunkName: "about" */ "../views/bannerToPkg.vue")
  },
  {
    path: "/address",
    name: "address",
    component: () => import(/* webpackChunkName: "about" */ "../views/address.vue")
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import(/* webpackChunkName: "about" */ "../views/pay.vue")
  },
  {
    path: "/appPermissions",
    name: "appPermissions",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/appPermissions.vue"
      )
  },
  {
    path: "/collectMessage",
    name: "collectMessage",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/collectMessage.vue"
      )
  },
  {
    path: "/licenseInformation",
    name: "licenseInformation",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/licenseInformation.vue"
      )
  },
  {
    path: "/thirdPartyMessage",
    name: "thirdPartyMessage",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/thirdPartyMessage.vue"
      )
  },
  {
    path: "/platformIntroduction",
    name: "platformIntroduction",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/platformIntroduction.vue"
      )
  },
  {
    path: "/videoShow",
    name: "videoShow",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/videoShow.vue"
      )
  },
  {
    path: "/goHomePage",
    name: "goHomePage",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/goHomePage.vue"
      )
  },
  {
    path: "/help",
    name: "help",
    component: () => import(/* webpackChunkName: "about" */ "../views/illustrate/help.vue")
  },
  {
    path: "/helpDetail",
    name: "helpDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/helpDetail.vue"
      )
  },
  {
    path: "/accountCancel",
    name: "accountCancel",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/accountCancel.vue"
      )
  },
  {
    path: "/superGroupRules",
    name: "superGroupRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/illustrate/superGroupRules.vue"
      )
  },
  // {
  //   path: '/turnTable',
  //   name: 'turnTable',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/turnTable.vue'),
  // },
  // {
  //   path: '/turnTableRecord',
  //   name: 'turnTableRecord',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/turnTableRecord.vue'),
  // },
  {
    path: "/turnTableAddress",
    name: "turnTableAddress",
    component: () => import(/* webpackChunkName: "about" */ "../views/turnTableAddress.vue")
  },
  {
    path: "/downloadApp",
    name: "downloadApp",
    component: () => import(/* webpackChunkName: "about" */ "../views/downloadApp.vue")
  },
  {
    path: "/monthReward",
    name: "monthReward",
    component: () => import(/* webpackChunkName: "about" */ "../views/monthRewards.vue")
  },
  {
    path: "/monthRewardDes",
    name: "monthRewardDes",
    component: () => import(/* webpackChunkName: "about" */ "../views/monthRewardDes.vue")
  },
  {
    path: "/illustrate",
    name: "illustrate",
    component: () => import(/* webpackChunkName: "about" */ "../views/illustrate.vue")
  },
  {
    path: "/appMethod",
    name: "appMethod",
    component: () => import(/* webpackChunkName: "about" */ "../views/appMethod.vue")
  },
  {
    path: "/gift",
    name: "gift",
    component: () => import(/* webpackChunkName: "about" */ "../views/gift.vue")
  },
  {
    path: "/giftRules",
    name: "giftRules",
    component: () => import(/* webpackChunkName: "about" */ "../views/giftRules.vue")
  },
  {
    path: "/kiddolCircle",
    name: "kiddolCircle",
    component: () => import(/* webpackChunkName: "about" */ "../views/kiddolCircle.vue")
  },
  {
    path: "/goodsDetail",
    name: "goodsDetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/goodsDetail.vue")
  },
  {
    path: "/mouse",
    name: "mouse",
    component: () => import(/* webpackChunkName: "about" */ "../views/mouse.vue")
  },
  {
    path: "/mouseRules",
    name: "mouseRules",
    component: () => import(/* webpackChunkName: "about" */ "../views/mouseRules.vue")
  },
  {
    path: "/mouseRecord",
    name: "mouseRecord",
    component: () => import(/* webpackChunkName: "about" */ "../views/mouseRecord.vue")
  },
  {
    path: "/topicRank",
    name: "topicRank",
    component: () => import(/* webpackChunkName: "about" */ "../views/topicRank.vue")
  },
  {
    path: "/topicRankRules",
    name: "topicRankRules",
    component: () => import(/* webpackChunkName: "about" */ "../views/topicRankRules.vue")
  },
  {
    path: "/recruit",
    name: "recruit",
    component: () => import(/* webpackChunkName: "about" */ "../views/showImg.vue")
  },
  {
    path: "/showImg",
    name: "showImg",
    component: () => import(/* webpackChunkName: "about" */ "../views/showImg.vue")
  },
  {
    path: "/superLink",
    name: "superLink",
    component: () => import(/* webpackChunkName: "about" */ "../views/superLink.vue")
  },
  {
    path: "/webView",
    name: "webView",
    component: () => import(/* webpackChunkName: "about" */ "../views/webView.vue")
  },
  {
    path: "/eliteRecruit",
    name: "eliteRecruit",
    component: () => import(/* webpackChunkName: "about" */ "../views/eliteRecruit.vue")
  },
  {
    path: "/eliteRecruitRule",
    name: "eliteRecruitRule",
    component: () => import(/* webpackChunkName: "about" */ "../views/eliteRecruitRule.vue")
  },
  {
    path: "/sendGoldProgress",
    name: "sendGoldProgress",
    component: () => import(/* webpackChunkName: "about" */ "../views/sendGoldProgress.vue")
  },
  {
    path: "/kaZhiYin",
    name: "kaZhiYin",
    component: () => import(/* webpackChunkName: "about" */ "../views/kaZhiYin.vue")
  },
  //activity

  //积分大转盘///////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/scoreTurntable",
    name: "scoreTurntable",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/scoreTurntable.vue"
      )
  },
  {
    path: "/scoreTurntableRules",
    name: "scoreTurntableRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/scoreTurntableRules.vue"
      )
  },
  {
    path: "/scoreTurntableRecord",
    name: "scoreTurntableRecord",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/scoreTurntableRecord.vue"
      )
  },
  ///////////////////////////////////////////////////////////////////////////////////////

  //2022-618大转盘///////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/618LuckyTurntable",
    name: "618LuckyTurntable",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/618LuckyTurntable.vue"
      )
  },
  {
    path: "/618SuperTurntable",
    name: "618SuperTurntable",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/618SuperTurntable.vue"
      )
  },
  {
    path: "/618TurntableRules",
    name: "618TurntableRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/618TurntableRules.vue"
      )
  },
  {
    path: "/618TurntableRecord",
    name: "618TurntableRecord",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/618TurntableRecord.vue"
      )
  },
  ///////////////////////////////////////////////////////////////////////////////////////

  //2022-双十一大转盘///////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/11LuckyTurntable",
    name: "11LuckyTurntable",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/11Turntable/LuckyTurntable.vue"
      )
  },
  {
    path: "/11SuperTurntable",
    name: "11SuperTurntable",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/11Turntable/SuperTurntable.vue"
      )
  },
  {
    path: "/11TurntableRecord",
    name: "11TurntableRecord",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/11Turntable/TurntableRecord.vue"
      )
  },
  // 活力值
  {
    path: "/scoreDetail",
    name: "scoreDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/11Turntable/scoreDetail.vue"
      )
  },
  ///////////////////////////////////////////////////////////////////////////////////////

  //2023-双十一大转盘///////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/2023/11SuperTurntable",
    name: "202311SuperTurntable",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/2023/11SuperTurntable.vue"
      )
  },
  {
    path: "/2023/11TurntableRules",
    name: "202311TurntableRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/2023/11TurntableRules.vue"
      )
  },
  {
    path: "/2023/11TurntableRecord",
    name: "202311TurntableRecord",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/2023/11TurntableRecord.vue"
      )
  },
  // 活力值
  {
    path: "/scoreDetail",
    name: "scoreDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/11Turntable/scoreDetail.vue"
      )
  },
  ///////////////////////////////////////////////////////////////////////////////////////

  //助力金///////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/assist",
    name: "assist",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/assist/index.vue"
      )
  },
  {
    path: "/assistDetail",
    name: "assistDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/assist/detail.vue"
      )
  },
  {
    path: "/assistList",
    name: "assistList",
    component: () => import(/* webpackChunkName: "about" */ "../views/activity/assist/list.vue")
  },
  {
    path: "/assistRules",
    name: "assistRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/assist/rules.vue"
      )
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////

  //金币///////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/myFriend",
    name: "myFriend",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/gold/myFriend.vue"
      )
  },
  {
    path: "/goldDetail",
    name: "goldDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/gold/goldDetail.vue"
      )
  },
  {
    path: "/myGoldDetail",
    name: "myGoldDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/gold/myGoldDetail.vue"
      )
  },
  {
    path: "/goldInvitation",
    name: "goldInvitation",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/gold/goldInvitation.vue"
      )
  },
  {
    path: "/invitationRules",
    name: "invitationRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/gold/invitationRules.vue"
      )
  },
  {
    path: "/goldDetailRules",
    name: "goldDetailRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/gold/goldDetailRules.vue"
      )
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // 红包雨
  {
    path: "/rewardRain",
    name: "rewardRain",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/rewardRain/index.vue"
      )
  },
  // 红包雨 _规则
  {
    path: "/rewardRainRule",
    name: "rewardRainRule",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/rewardRain/rule.vue"
      )
  },
  // 红包雨 _我的奖品
  {
    path: "/rewardRainAward",
    name: "rewardRainAward",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/rewardRain/award.vue"
      )
  },
  // kiddol圈排行榜
  {
    path: "/kiddolRank",
    name: "kiddolRank",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/kiddolRank/index.vue"
      )
  },
  // kiddol圈排行榜_活动规则
  {
    path: "/kiddolRankRule",
    name: "kiddolRankRule",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/kiddolRank/rule.vue"
      )
  },
  //  kiddol圈排行榜_app兼容更新页
  {
    path: "/kiddolAppUpdate",
    name: "kiddolAppUpdate",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/kiddolRank/updateApp.vue"
      )
  },

  // 收益说明
  {
    path: "/incomeDesc",
    name: "incomeDesc",
    component: () => import(/* webpackChunkName: "about" */ "../views/incomeDesc.vue")
  },
  {
    path: "/incomeDescNew",
    name: "incomeDescNew",
    component: () => import(/* webpackChunkName: "about" */ "../views/incomeDescNew.vue")
  },
  {
    path: "/incomeDescVer4",
    name: "incomeDescVer4",
    component: () => import(/* webpackChunkName: "about" */ "../views/incomeDescVer4.vue")
  },
  // 收益说明
  {
    path: "/incomeDescOfGroup",
    name: "incomeDescOfGroup",
    component: () => import(/* webpackChunkName: "about" */ "../views/incomeDescOfGroup.vue")
  },

  //default
  {
    path: "/turnTable",
    name: "turnTable",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/2023/11SuperTurntable.vue"
      )
  },
  {
    path: "/turnTableRecord",
    name: "turnTableRecord",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/11Turntable/TurntableRecord.vue"
      )
  },
  {
    path: "/turnTable2",
    name: "turnTable2",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/treasure/index.vue"
      )
  },
  {
    path: "/turnTableRules",
    name: "turnTableRules",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/activity/turnTable/618TurntableRules.vue"
      )
  },
  // 七鱼客服
  {
    path: "/contact/index",
    name: "contact/index",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/contact/index.vue"
      )
  },
  {
    path: "/contact/card",
    name: "contact/card",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/contact/card.vue"
      )
  },
  // 嗑了个豆
  {
    path: "/kedou",
    name: "kedou",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/kedou/index.vue"
      )
  },
  // 2022年货节-招募活动
  {
    path: "/recruit/task",
    name: "/recruit/task",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/recruit/task.vue"
      )
  },
  // 2022年货节-招募活动-填写资料
  {
    path: "/recruit/writeInfo",
    name: "/recruit/writeInfo",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/recruit/writeInfo.vue"
      )
  },
  // 2022年货节-招募活动-操作指南
  {
    path: "/recruit/guide",
    name: "/recruit/guide",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/recruit/guide.vue"
      )
  },

  // （磕豆）积分明细
  {
    path: "/score",
    name: "/score",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/score/jifen.vue"
      )
  },
  ...activityRouter,
  ...addolRouter
];

const router = new VueRouter({
  routes
});

export default router;
