let httpUrl = 'https://activity-test.manqu88.com';
if (process.env.VUE_APP_ENV === 'development') {
  httpUrl = '/dev';
}
if (process.env.VUE_APP_ENV === 'production'){
  httpUrl = 'https://activity.manqu88.com';
}
if (process.env.VUE_APP_ENV === 'test') {
  httpUrl = 'https://activity-test.manqu88.com';
}
const baseUrl = httpUrl;
export default baseUrl;
